// Localhost settings. Should be uncommented if you want to connect to localhost APIs
//export const api_path = "http://localhost:8000/";
//export const pusher_id = "140ea2a588b03163188f";
//export const pusher_auth = "http://localhost:8000/api/auth/pusher";

// Codeforgery Dev server settings. Should be uncommented if you want to connect to the DEV SERVER APIs
export const api_path = "https://mhc.codeforgery.xyz/";
export const pusher_id = "140ea2a588b03163188f";
export const pusher_auth = "https://mhc.codeforgery.xyz/api/auth/pusher";



/* ====> LIVE SETTINGS <==== */
/* ====> UNCOMMENT ONLY WHEN DEPLOYING TO LIVE SERVER <==== */
//export const api_path = "https://api.mentalhealthcheck.org/";
//export const pusher_id = "1889cd38eecb78da7329";
//export const pusher_auth = "https://api.mentalhealthcheck.org/api/auth/pusher";
/* ======================================================== */