export default {
  namespaced: true,
  state: {
    questionnaireProgress: 0,
    questionnaireCategory: null,
    questionnaireCategoryId: null,
    currentQuestionnaire: [],
    questionIndex: 0,
    show: true,
    page: 0,
    backOptionFlag: false,
    userInvitedToken: null,
  },
  getters: {
    questionnaireCategory(state) {
      return state.questionnaireCategory
    },
    currentQuestionnaire(state) {
      return state.currentQuestionnaire
    }
  },
  mutations: {

    // set flag if the user was invited..
    setUserInvitedToken: (state, payload) => {
      state.userInvitedToken = payload
    },

    // Progress in questionnaire (progressbar)
    setQuestionnaireProgress: (state, payload) => {
      state.questionnaireProgress = payload
    },

    // set back option flag
    setBackOptionFlag: (state, payload) => {
      state.backOptionFlag = payload
    },

    // Choosen Questionnaire in form (level 3)
    setCurrentQuestionnaire: (state, payload) => {
      state.currentQuestionnaire = payload
    },

    // Index of current question
    setQuestionIndex: (state, payload) => {
      state.questionIndex = payload
    },

    // Show/hide overlay - loading for components
    setShow: (state, payload) => {
      state.show = payload
    },

    // Number of page (moving between screens in component)
    setPage: (state, payload) => {
      state.page = payload
    },

    // Questionnaire Main Category (level 1)
    setQuestionnaireCategory: (state, payload) => {
      state.questionnaireCategory = payload
    },
    
    // Questionnaire Main Category Id (level 1)
    setQuestionnaireCategoryId: (state, payload) => {
      state.questionnaireCategoryId = payload
    },

    // Questionnaire Sub Category (level 2)
    setQuestionnaireSubCategory: (state, payload) => {
      state.questionnaireSubCategory = payload
    },


    /* 
      INCREMENTS & DECREMENTS 
    */
    
    // Index of current question increment (+1)
    incrementPage (state) {
      state.page++
    },

    // Index of current question increment (+1)
    incrementQuestionIndex (state) {
      state.questionIndex++
    },

    // Index of current question decrement (-1)
    decrementQuestionIndex (state) {
      state.questionIndex--
    },
    

    /* 
      OTHER 
    */

    // Reset form params
    resetFormParams (state) {
      state.questionnaireProgress = 0
      state.currentQuestionnaire = []
      state.questionIndex = 0
      state.page = 0
      state.questionnaireCategory = null
      state.questionnaireCategoryId = null
    },
  },
  actions: {
    // Index of current question increment (+1)
    incrementPage (context) {
      context.commit('incrementPage')
    },

    // Index of current question increment (+1)
    incrementQuestionIndex (context) {
      context.commit('incrementQuestionIndex')
    },
    
    // Index of current question increment (-1)
    decrementQuestionIndex (context) {
      context.commit('decrementQuestionIndex')
    },
    
    /// Reset form params
    resetFormParams (context) {
      context.commit('resetFormParams')
    },

  }
}
