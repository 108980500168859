import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index";
import EventBus from "@/main.js"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'form',
      component: () => import('@/views/form/Form.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/form',
      name: 'form',
      component: () => import('@/views/form/Form.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/form/:survey',
      name: 'survey',
      component: () => import('@/views/form/Survey.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/reminder',
      name: 'reminder',
      component: () => import('@/views/form/Reminder.vue'),
      meta: {
        layout: 'blank',
        requiresAuth: true
      },
    },
    {
      path: '/score',
      name: 'score',
      component: () => import('@/views/form/Score.vue'),
      meta: {
        layout: 'blank',
        requiresAuth: true
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Your profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
            requiresAuth: true
          },
        ],
      },
    },
    {
      path: '/my-hub',
      name: 'my-hub',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'My Hub',
        breadcrumb: [
          {
            text: 'My Hub'
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/my-hub/users-that-you-follow',
      name: 'users-you-follow',
      component: () => import('@/layouts/components/UsersYouFollow.vue'),
      meta: {
        pageTitle: 'Profiles that You Follow',
        breadcrumb: [
          {
            text: 'My Hub',
            active: true,
          },
          {
            text: 'Profiles that You Follow',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },
    {
      path: '/my-hub/users-that-follow-you',
      name: 'users-follow-you',
      component: () => import('@/layouts/components/UsersFollowYou.vue'),
      meta: {
        pageTitle: 'Users that Follow You',
        breadcrumb: [
          {
            text: 'My Hub',
            active: true,
          },
          {
            text: 'Users that Follow You',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },
    {
      path: '/my-hub/followers',
      name: 'followers',
      component: () => import('@/views/users/Followers.vue'),
      meta: {
        pageTitle: 'Manage followers',
        breadcrumb: [
          {
            text: 'My Hub',
            active: true,
          },
          {
            text: 'Manage followers',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },

    // My checks
    {
      path: '/my-checks',
      name: 'my-checks',
      component: () => import('@/views/checks/MyChecks.vue'),
      meta: {
        pageTitle: 'Mental Health Check Submissions',
        breadcrumb: [
          {
            text: 'My Hub',
          },
          {
            text: 'Mental Health Check Submissions',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },
    {
      path: '/my-checks/questionnaire-submissions/:id',
      name: 'questionnaire-submissions',
      component: () => import('@/views/questionnaires/QuestionnairesAttempts.vue'),
      meta: {
        pageTitle: 'Mental Health Check Submissions',
        breadcrumb: [
          {
            text: 'My Checks',
          },
          {
            text: 'Mental Health Check Submissions',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },
    {
      path: '/my-checks/questionnaire-details/:id',
      name: 'questionnaire-details',
      component: () => import('@/views/questionnaires/QuestionnaireDetails.vue'),
      meta: {
        pageTitle: 'Submission Details',
        breadcrumb: [
          {
            text: 'My Checks',
          },
          {
            text: 'Questionnaire Submissions',
          },
          {
            text: 'Submission Details',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },

    // Feeling index
    {
      path: '/my-checks/feeling-index/questionnaire-details/:id',
      name: 'questionnaire-feelings-index-details',
      component: () => import('@/views/feelings-index/QuestionnaireDetails.vue'),
      meta: {
        pageTitle: 'Submission Details',
        breadcrumb: [
          {
            text: 'My Checks',
          },
          {
            text: 'Questionnaire Submissions',
          },
          {
            text: 'Submission Details',
            active: true,
          },
        ],
        requiresAuth: true
      },
      props: true,
    },

    // Toolkits
    {
      path: '/learn-and-develop',
      name: 'learn-and-develop',
      component: () => import('@/views/toolkit/ToolkitList.vue'),
      meta: {
        pageTitle: 'Learn and Develop',
        breadcrumb: [
          {
            text: 'Learn and Develop'
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop/:slug',
      name: 'learn-and-develop-single',
      component: () => import('@/views/toolkit/Worksheet.vue'),
      meta: {
        pageTitle: 'Learn and Develop',
        breadcrumb: [
          {
            text: 'Learn and Develop'
          },
        ],
        requiresAuth: true
      },
      props: true
    },
    {
      path: '/learn-and-develop/view/all',
      name: 'learn-and-develop-grid',
      component: () => import('@/views/toolkit/ToolkitListAll.vue'),
      meta: {
        pageTitle: 'Learn and Develop',
        breadcrumb: [
          {
            text: 'Learn and Develop'
          },
        ],
        requiresAuth: true
      },
    },

    // Payment
    // Subscription
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/payment/Subscription.vue'),
      meta: {
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'Subscription'
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/change-subscription-plan',
      name: 'change-subscription-plan',
      component: () => import('@/views/payment/ChangeSubscriptionPlan.vue'),
      meta: {
        pageTitle: 'Subscription plan',
        breadcrumb: [
          {
            text: 'Subscription'
          },
          {
            text: 'Change plan'
          },
        ],
        requiresAuth: true
      },
    },

    // Additional
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/additional/FAQ.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/additional/ContactUs.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: () => import('@/views/additional/TermsAndConditions.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/additional/PrivacyPolicy.vue'),
      meta: {
        layout: 'blank',
      },
    },

    // Auth
    {
      path: '/authredirect/:token',
      name: 'social-auth',
      component: () => import('@/views/auth/SocialAuth.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'blank',
        hideForAuth: true
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'blank',
        hideForAuth: true
      },
    },
    {
      path: '/invite/register/:token',
      name: 'invite-register',
      component: () => import('@/views/auth/InviteRegister.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'blank',
        hideForAuth: true
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'blank',
        hideForAuth: true
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    // Modals    
    {
      path: '/help--about-us',
      name: 'help--about-us',
      meta: {
        modal: true,
        event: "openAboutUs"
      },
    },
    {
      path: '/help--what-we-do',
      name: 'help--what-we-do',
      meta: {
        modal: true,
        event: "openWhatWeDo"
      },
    },
    {
      path: '/help--how-it-works',
      name: 'help--how-it-works',
      meta: {
        modal: true,
        event: "howItWorks"
      }
    },
    {
      path: '/help--the-science',
      name: 'help--the-science',
      meta: {
        modal: true,
        event: "openScience"
      }
    },
    {
      path: '/help--tour',
      name: 'help--tour',
      meta: {
        modal: true,
        event: "runTour"
      }
    },
    {
      path: '/help--pricing',
      name: 'help--pricing',
      meta: {
        modal: true,
        event: "openPricing"
      }
    },
    {
      path: '/help--invite-friends',
      name: 'help--invite-friends',
      meta: {
        modal: true,
        event: "inviteFriends"
      }
    },    
    {
      path: '/my--daily-check-in',
      name: 'my--daily-check-in',
      meta: {
        modal: true,
        event: "myDailyCheckIn"
      }
    },
  ],
  scrollBehavior () {
    document.getElementById('app').scrollIntoView();
  }
})

router.beforeEach((to, from, next) => {
  
  // Call Modals from sidebar navigation
  if(to.name.includes('help--')) {
    next(from)
    EventBus.$emit(to.meta.event)
  }

  if(to.name == 'my--daily-check-in') {
    next(from)
    EventBus.$emit(to.meta.event)
  }

  if(to.name == 'what--') {
    next(from)
    EventBus.$emit(to.meta.event)
  }

  //Permissions for logged and not logged users
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Auth is necessary
      if (store.state.app.userData == null || store.state.app.userData.length == 0) {
          next({ path: '/login' });
        } else {
          // If the user has not verified email
          if(store.state.app.userData.email_verified_at == null) {
            if (to.name == 'my-hub' || to.name == 'form' || to.name == 'survey' || to.name == 'score' || to.name == 'reminder') {
              next();
            } else {
              // Event for verification toast
              EventBus.$emit('needVerificationToast')
            }
          } else {
            next()
          }
        }
      } else {
        next();
    }
    
    if (to.matched.some(record => record.meta.hideForAuth)) {
      // If you are logged in, just go to the dashboard
      if (store.state.app.userData != null) {
        if(store.state.app.userData.length != 0) {
          next({ path: '/my-hub' });
        } else {
        next();
        }
      } else {
        next();
      }
    }

})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
