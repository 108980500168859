import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'


// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import axios from '@axios'
import moment from 'moment'
import VueTour from 'vue-tour'
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'

// Analytics
import VueAnalytics from 'vue-analytics';

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// VueTour
Vue.use(VueTour)

// youtube & vimeo
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)

// Composition API
Vue.use(VueCompositionAPI)

// Google Analytics
Vue.use(VueAnalytics, {
  id: 'G-C464FXHFB1',
  router
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// EventBus
const EventBus = new Vue();
export default EventBus;

// Filters
Vue.filter('prepareDate', function (value) {
  if (!value) return ''
  // Get format "Jun 8, 2021, 14:53"
  value = moment(value).format("ll, HH:mm");
  let current_date = moment();
  let final_number = Math.abs(current_date.diff(value, "days"));

  if(final_number >= 30) {
    return value
  }

  let day = "days ago";
  if (final_number == 1) {
      day = "day ago";
  }

  value = final_number + " " + day;

  if(value == '0 days ago') {
    value = 'Today'
  }

  return value
})

Vue.filter('timestamp', function (value) {
  if (!value) return ''
  // Get format "Jun 8, 2021, 14:53"
  value = moment.unix(value).format("LLL")
  
  return value
})

Vue.filter('classic', function (value) {
  if (!value) return ''
  // Get format "Jun 8, 2021, 14:53"
  value = moment(value).format("ll, HH:mm");
  return value
})

Vue.filter('justDate', function (value) {
  if (!value) return ''
  // Get format Mar 1, 2022
  value = moment.unix(value).format("ll")
  
  return value
})

// Mixins (methods)
Vue.mixin({
  methods: {
    makeAvatar(username) {
      // Get username and return avatar with first letter
      if(username == "" || username == undefined || username == null) {
        return "https://eu.ui-avatars.com/api/?name=n";
      }
      return "https://eu.ui-avatars.com/api/?name=" + username.charAt(0)
    }
  },
})

// Request interceptor for API calls
axios.interceptors.request.use(
  async config => {
    const userDataLS = localStorage.getItem('userData');

    if(userDataLS != null) {
      const userData = JSON.parse(userDataLS)
      const token = `${userData.token_type} ${userData.accessToken}`;
  
      config.headers = { 
        'Authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

// 401 response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  let error_msg = {
    'response': error.response,
    'user': localStorage.getItem('userData')
  }

  let formData = new FormData;
  formData.append('error_message', JSON.stringify(error_msg))

  axios.post("/api/error-log", formData)
    .then((res) => {
    })
    .catch((err) => {
    })
  
  if (error.response.status === 401) {
    //Remove userData from localStorage and redirect to login
    store.commit('app/removeUserData')
    localStorage.removeItem('userData')
    
    router.push({ path: '/login', query: { sessionExp: '1' } }).catch(()=>{});
  } else {
    return Promise.reject(error);
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
