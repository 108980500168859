import axios from "@axios"
import EventBus from "../../main.js"

export default {
    namespaced: true,
    state: {
        currentToolkit: {},
        toolkitProgress: 0,
        worksheetIndex: 0,
        nextBtnStatus: true, // on init player status is true, on ended listener status is false

        toolkitList: [],
        toolkitListTotal: 0,
        recommendedToolkitList: [],

        questionnaireCategoriesOptions: [],

        showOverlay: false,
        
    },
    getters: {
        currentToolkit(state) {
            return state.currentToolkit;
        },
        toolkitProgress(state) {
            return state.toolkitProgress;
        },
        worksheetIndex(state) {
            return state.worksheetIndex;
        },
        nextBtnStatus(state) {
            return state.nextBtnStatus;
        },

        toolkitList(state) {
            return state.toolkitList
        },
        toolkitListTotal(state) {
            return state.toolkitListTotal
        },
        recommendedToolkitList(state) {
            return state.recommendedToolkitList
        },
        questionnaireCategoriesOptions(state) {
            return state.questionnaireCategoriesOptions
        },
        showOverlay(state) {
            return state.showOverlay
        },
    },
    mutations: {
        setCurrentToolkit(state, toolkitSlug) {
            axios.get("/api/auth/toolkit/enroll/" + toolkitSlug)
            .then((res) => {
                if(res != undefined) {
                    state.currentToolkit = res.data
                        
                    // Loop for all worksheets to find where user paused
                    let i = 0;

                    if(state.currentToolkit.enrollment.toolkit.worksheets[i].results != undefined) {
                        for(i=0; i<state.currentToolkit.enrollment.toolkit.worksheets.length; i++) {
                            // If result length is equal zero set worksheet index
                            if(state.currentToolkit.enrollment.toolkit.worksheets[i].results.length == 0) {
                                // Set worksheetIndex
                                state.worksheetIndex = i

                                // Set progress bar
                                state.toolkitProgress = (state.worksheetIndex / state.currentToolkit.enrollment.toolkit.worksheets.length) * 100

                                break;
                            }
                        }
                    }

                    // Go to finish page
                    if(state.currentToolkit.enrollment.toolkit.worksheets.length == i) {
                        state.worksheetIndex = i
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
        },
        toolkitProgress(state, progress) {
            state.toolkitProgress = progress;
        },
        // worksheet index
        worksheetIndex(state, index) {
            state.worksheetIndex = index;
        },
        incrementWorksheetIndex(state) {
            state.worksheetIndex++
        },
        resetWorksheetIndex(state) {
            state.worksheetIndex = 0
        },

        // next button
        changeNextBtnStatus(state, status) {
            state.nextBtnStatus = status
        },

        getToolkitList(state, { letter, category, search, page, recommended, questionnaire, emotion }) {
            let url = "/api/auth/toolkits?"

            if(letter != undefined && letter != 'all') {
                url += "first_letter=" + letter + "&"
            }

            if(category != 'all') {
                url += "category=" + category + "&"
            }

            if(search != '') {
                url += "title=" + search + "&"
            }
            
            if(recommended == true) {
                url += "recommended=true&questionnaire=all" + "&"
            }
            
            if(questionnaire != null) {
                url += "questionnaire=" + questionnaire
            }

            if(emotion != null) {
                url += "emotion=" + emotion + "&"
            }

            url += "page=" + page

            axios.get(url)
            .then((res) => {
                if(res != undefined) {
                    state.toolkitList = res.data
                    state.toolkitListTotal = 10
                    
                    state.showOverlay = false
                    EventBus.$emit('updatedToolkitList');

                    // toolkit store proveri list total -- za paginaciju na toolkitList
                }
            })
            .catch((err) => {
                console.log(err);
                state.showOverlay = false
            })
        },
        
        getRecommendedToolkitList(state) {
            axios.get("/api/auth/toolkits?recommended=true")
            .then((res) => {
                if(res != undefined) {
                    state.recommendedToolkitList = res.data


                    let questionnaires = [
                      { text: 'All', value: 'all'}
                    ];
                
                    for(let i=0; i<res.data.questionnaires.length; i++) {
                      questionnaires.push(
                        {
                          text: res.data.questionnaires[i].subcategory.questionnairecategory.name + ' > ' + res.data.questionnaires[i].title,
                          value: res.data.questionnaires[i].id
                        }
                      )
                    }

                    state.questionnaireCategoriesOptions = questionnaires;
                }
            })
            .catch((err) => {
                console.log(err);
            })
        },

        setCurrentWorksheetResponse(state, response) {
            state.currentToolkit.enrollment.toolkit.worksheets[state.worksheetIndex].response = response
        },
        resetCurrentWorksheetResponse(state) {
            state.currentToolkit.enrollment.toolkit.worksheets[state.worksheetIndex].response = ""
        },
        showOverlay(state, show) {
            state.showOverlay = show;
        },
    },
    actions: {
        setToolkitProgress({commit}, progress) {
            commit('toolkitProgress', progress)
        },
        setCurrentToolkit({commit}, toolkitSlug) {
            commit('setCurrentToolkit', toolkitSlug)
        },
        // worksheet index
        setWorksheetIndex({commit}, index) {
            commit('worksheetIndex', index)
        },
        incrementWorksheetIndex({commit}) {
            commit('incrementWorksheetIndex')
        },
        resetWorksheetIndex({commit}) {
            commit('resetWorksheetIndex')
        },

        // next button
        changeNextBtnStatus({commit}, status) {
            commit('changeNextBtnStatus', status)
        },

        getToolkitList({commit}, { letter, category, search, page, recommended, questionnaire, emotion }) {
            commit('getToolkitList', { letter, category, search, page, recommended, questionnaire, emotion })
        },
        getRecommendedToolkitList({commit}) {
            commit('getRecommendedToolkitList')
        },
        setCurrentWorksheetResponse({commit}, response) {
            commit('setCurrentWorksheetResponse', response)
        },
        resetCurrentWorksheetResponse({commit}) {
            commit('resetCurrentWorksheetResponse')
        },

        // Overlay
        showOverlay({commit}, show) {
            commit('showOverlay', show)
        }
    }
  }
  