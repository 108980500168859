import axios from "@axios"

export default {
    namespaced: true,
    state: {
        overlay: true,
        currentStep: 1,
        selectedPlan: null,
        paymentMethodSelected: null,
        subscribeDetails: {},
        isOnGracePeriod: false,
        gracePeriodExpiration: null
    },
    getters: {
        overlay(state) {
            return state.overlay;
        },
        currentStep(state) {
            return state.currentStep;
        },
        selectedPlan(state) {
            return state.selectedPlan;
        },
        paymentMethodSelected(state) {
            return state.paymentMethodSelected;
        },
        subscribeDetails(state) {
            return state.subscribeDetails;
        },
        isOnGracePeriod(state) {
            return state.isOnGracePeriod;
        },
        gracePeriodExpiration(state) {
            return state.gracePeriodExpiration;
        },
    },
    mutations: {
        // Overlay
        setOverlay(state, val) {
            state.overlay = val
        },

        // Current step
        setCurrentStep(state, val) {
            state.currentStep = val
        },
        incrementCurrentStep(state) {
            state.currentStep++
        },
        decrementCurrentStep(state) {
            state.currentStep--
        },

        // Plan
        setSelectedPlan(state, val) {
            state.selectedPlan = val
        },
        setPaymentMethodSelected(state, val) {
            state.paymentMethodSelected = val
        },
        
        // Subscribe Details
        setSubscribeDetails(state, val) {
            state.subscribeDetails = val
        },

        // Grace period
        setGracePeriod(state, val) {
            axios.get("/api/auth/payment/is-on-grace-period")
            .then((res) => {
                if(res != undefined) {
                    state.isOnGracePeriod = res.data.is_on_grace_period
                    state.gracePeriodExpiration = res.data.expiration_date
                }
            })
            .catch((err) => {
                console.log(err);
            })
        },
    },
    actions: {
        // Overlay
        setOverlay({commit}, state) {
            commit('setOverlay', state)
        },

        // Current step
        setCurrentStep({commit}, state) {
            commit('setCurrentStep', state)
        },
        incrementCurrentStep({commit}) {
            commit('incrementCurrentStep')
        },
        decrementCurrentStep({commit}) {
            commit('decrementCurrentStep')
        },

        // Plan
        setSelectedPlan({commit}, state) {
            commit('setSelectedPlan', state)
        },
        setPaymentMethodSelected({commit}, state) {
            commit('setPaymentMethodSelected', state)
        },
        
        // Subscribe Details
        setSubscribeDetails({commit}, state) {
            commit('setSubscribeDetails', state)
        },

        // Grace period
        setGracePeriod({commit}) {
            commit('setGracePeriod')
        }
    }
  }
  