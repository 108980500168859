import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import formStoreModule from '../views/form/formStoreModule'
import toolkitStoreModule from '../views/toolkit/toolkitStoreModule'
import subscriptionStoreModule from '../views/payment/subscriptionStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    formStoreModule,
    toolkitStoreModule,
    subscriptionStoreModule
  },
  strict: process.env.DEV,
})
